@import '~stylesheets/breakpoint';
//
// Base styles
//

.alert {
  align-items: center;
  animation: slide-down .5s ease-in-out;
  border: $alert-border-width solid transparent;
  border-radius: .25rem;
  box-shadow: 0 4px 4px rgba($black, .05);
  display: flex;
  flex-direction: row;
  font-size: $form-label-font-size;
  left: 50%;
  min-height: calc(42px - 2*$alert-padding-y);
  min-width: 440px;
  position: fixed;
  top: 1.5rem;
  transform: translate(-50%);
  max-width: 720px;
  z-index: 1030;

  @include at-breakpoint(sm) {
    min-width: 320px;
  }
}

@keyframes slide-down {
  from {
    margin-top: -6rem;
  }

  to {
    margin-top: 0;
  }
}

// Headings for larger alerts
.alert-heading {
  // Specified to prevent conflicts of changing $headings-color
  color: inherit;
}

// Provide class for links that match alerts
.alert-link {
  font-weight: $alert-link-font-weight;
}


// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissible {
  padding-right: $alert-dismissible-padding-r;
}


// scss-docs-start alert-modifiers
// Generate contextual modifier classes for colorizing the alert.
.alert-success {
  background-color: $success-light;
  color: $theme-color-primary;
}

.alert-danger {
  background-color: $red-light;
  color: $theme-color-primary;
}

.alert-warning {
  background-color: $orange-light;
  color: $theme-color-primary;
}
