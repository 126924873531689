@import '~stylesheets/variables';

.ltr {
  text-align: left;
}

.rtl {
  text-align: right;
}

.paragraph {
  font-size: $h5-font-size !important;
  position: relative;
}

.link {
  color: $blue;
  font-weight: $font-weight-semibold;

  &:hover {
    color: $blue;
    font-weight: $font-weight-semibold;
  }
}
