@import '~stylesheets/variables';

.h1 {
  font-size: $h1-font-size !important;
}

.h2 {
  font-size: $h2-font-size !important;
}

.h3 {
  font-size: $h3-font-size !important;
}

.h4 {
  font-size: $h4-font-size !important;
}

.h5 {
  font-size: $h5-font-size !important;
}

.h6 {
  font-size: $h6-font-size !important;
}

.text-italic {
  font-style: italic;
}

.text-underline {
  text-decoration: underline;
}
