@import '~stylesheets/variables';

.link-editor {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: $zindex-modal + 1; // Should be greater than modals so it can show up on top. for instance on the send rejection email flow
  max-width: 400px;
  width: 100%;
  opacity: 0;
  background-color: #fff;
  box-shadow: 0px 4px 16px 2px #272C5C1A;
  transition: opacity 0.5s;
  will-change: transform;
}

.link-editor .link-input {
  &:hover, &:active, &:focus-visible {
    box-shadow: 0 0 0 1px $blue;
  }

  box-shadow: 0 0 0 1px $blue-gray-500;
  width: 300px;
  border: 0;
  outline: 0;
}

.link-editor a.link-confirm {
  color: $blue;
}
