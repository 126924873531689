@import '~stylesheets/variables';

.app {
  background: $theme-bg-light;
}

.app-nav .submenu-toggle[aria-expanded='true'] .submenu-arrow svg {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=2)';
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

.app-footer {
  .copyright {
    font-size: 0.8125rem;
  }
}

.app-wrapper {
  @include transition(all 0.4s ease-in-out);
}

.app-page-title,
.page-title {
  margin-bottom: 1.5rem;
}

.app-card,
.card {
  position: relative;
  background: $white;
  border: 0;
  @include border-radius(1rem);

  &.border-left-decoration {
    border-left: 4px solid $theme-color-primary;
  }

  .app-card-link-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
  }

  .app-card-header,
  .card-header {
    background: none;
    border-bottom: 1px solid $theme-border-color;
  }

  .app-card-title,
  .card-title {
    font-size: 1.125rem;
    margin-bottom: 0;
  }

  .card-header-action {
    font-size: 0.875rem;
    a:hover {
      text-decoration: underline;
    }
  }

  .form-select-holder {
    display: inline-block;
  }
}

.app-card-stat {
  text-align: center;
  .stats-type {
    font-size: 0.875rem;
    color: $theme-text-color-light;
    text-transform: uppercase;
  }

  .stats-figure {
    font-size: 2rem;
    color: $theme-text-color-primary;
  }

  .stats-meta {
    font-size: 0.875rem;
    color: $theme-text-color-light;
  }
}

.app-card-progress-list {
  .item {
    position: relative;
    border-bottom: 1px solid $theme-border-color;
    &:hover {
      .title {
        color: $theme-text-color-primary;
      }
    }
    &:last-child {
      border: none;
    }

    .title {
      font-size: 0.875rem;
      font-weight: 500;
    }
    .meta {
      font-size: 0.875rem;
      color: $theme-text-color-light;
    }
  }

  .item-link-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
  }
  .progress {
    height: 0.5rem;
  }
}

.app-card-stats-table {
  .table {
    font-size: 0.875rem;
  }
  .meta {
    color: $theme-text-color-light;
    font-weight: 500;
    font-size: 0.875rem;
  }

  .stat-cell {
    text-align: right;
  }
}

.app-card-basic {
  height: 100%;

  .title {
    font-size: 1rem;
  }
}

.app-card,
.card {
  .app-icon-holder {
    display: inline-block;
    background: lighten($theme-color-primary, 60%);
    color: $theme-color-primary;
    width: 50px;
    height: 50px;
    padding-top: 10px;
    font-size: 1rem;
    text-align: center;

    &.icon-holder-mono {
      background: $theme-bg-light;
      color: $theme-text-color-light;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }
  .app-card-body {
    &.has-card-actions {
      position: relative;
      padding-right: 1rem !important;
    }
    .app-card-actions {
      display: inline-block;
      width: 30px;
      height: 30px;
      text-align: center;
      @include border-radius(50%);
      position: absolute;
      z-index: 10;
      right: 0.75rem;
      top: 0.75rem;

      &:hover {
        background: $theme-bg-light;
      }

      .dropdown-menu {
        font-size: 0.8125rem;
      }
    }
  }
}

.app-card-doc {
  &:hover {
    @include box-shadow(0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important);
  }
  .app-card-thumb-holder {
    background: mix($theme-bg-light, black, 95%);
    text-align: center;
    position: relative;
    height: 112px;

    .app-card-thumb {
      overflow: hidden;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: black;
    }
    .thumb-image {
      @include opacity(0.7);
      width: 100%;
      height: auto;
    }
    &:hover {
      background: mix($theme-bg-light, white, 50%);
      .thumb-image {
        @include opacity(1);
      }
    }
    .badge {
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
    }

    .icon-holder {
      font-size: 40px;
      display: inline-block;
      margin: 0 auto;
      width: 80px;
      height: 80px;
      background: $white;
      padding-top: 10px;
      .pdf-file {
        color: $theme-color-pdf;
      }
      .text-file {
        color: $theme-color-word;
      }
      .excel-file {
        color: $theme-color-excel;
      }
      .ppt-file {
        color: $theme-color-ppt;
      }
      .video-file {
        color: $theme-color-video;
      }
      .zip-file {
        color: $theme-color-zip;
      }
    }
  }

  .app-doc-title {
    font-size: 0.875rem;
    a {
      color: $theme-text-color-primary;
    }
    &.truncate {
      max-width: calc(100% - 30px);
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .app-doc-meta {
    font-size: 0.75rem;
  }
}

.table-search-form {
  .form-control {
    height: 2rem;
    min-width: auto;
  }
}

.app-dropdown-menu {
  font-size: 0.875rem;
}

th .current {
  color: $black;
}

th .asc {
  color: $black;
}

th .desc {
  color: $black;
}

.app-card-table {
  .table {
    font-size: 0.875rem;
    border-bottom: none;

    th,
    td {
      border-color: $theme-border-color;
      color: $theme-text-color-secondary;
      vertical-align: middle;
      height: 60px;
    }

    td a:not(.btn){
      color: $blue;
    }
  }
  th,
  td {
    span {
      display: inline-block;
    }
    .note {
      display: block;
      color: $theme-text-color-light;
      font-size: 0.75rem;
    }
  }

  .truncate {
    max-width: 250px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.nav-tabs,
.app-nav-tabs {
  background: $white;
  padding: 0;
  .nav-link {
    color: $dark-200;
    font-weight: $font-weight-bold;
    border-bottom: 2px solid $white;
    &.active {
      color: $theme-color-primary;
      border-bottom: 2px solid $theme-color-primary;
      &:hover {
        border-bottom: 2px solid $theme-color-primary;
      }
    }
    &:hover {
      background: none;
      border-bottom: 2px solid $dark-200;
    }
  }

  .nav-text {
    color: $theme-text-color-secondary;
    font-size: 0.875rem;
    font-weight: bold;
    border: 0px;
    &.active {
      color: $theme-color-primary;
      border-bottom: 2px solid $theme-color-primary;
    }
  }
}

.app-pagination {
  .pagination {
    font-size: 0.875rem;
    .page-link {
      color: $theme-text-color-primary;
      padding: 0.25rem 0.75rem;
      border: none;
      border-radius: 4px;
      z-index: unset;
    }
    .page-item {
      margin-left: 0.125rem;
      margin-right: 0.125rem;
      &.active {
        .page-link {
          background: $theme-bg-light;
        }
      }
      &.disabled {
        .page-link {
          color: lighten($theme-text-color-light, 10%);
        }
      }
    }
  }
}

.app-card-accordion {
  .app-card-title,
  .card-title {
    font-size: 1.125rem;
  }

  .faq-accordion {
    .card {
      @include border-radius(0);
      border: none;
      border-bottom: 1px solid $theme-border-color;
      &:last-child {
        border-bottom: none;
      }
    }
    .card-header {
      padding: 1rem;

      @include border-radius(0);
      border: none;
      background: none;
      padding-left: 0;
    }
    .card-title {
      margin-bottom: 0;
    }

    .card-toggle {
      padding: 0;
      text-decoration: none;
      color: $theme-color-primary;
      font-size: 1rem;
    }
    .card-body {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
      font-size: 1rem;
    }
  }
}

.settings-section {
  .section-title {
    font-size: 1.25rem;
  }
  .section-intro {
    font-size: 0.875rem;
  }
}

.app-card-settings {
  font-size: 1rem;

  .form-label {
    font-weight: $font-weight-semibold;
  }

  .form-control {
    font-size: 1rem;
  }
}

.app-404-page {
  padding-top: 2rem;

  .page-title {
    font-size: 3rem;
    line-height: 0.8;
    font-weight: $font-weight-bold;
    span {
      font-size: 1.5rem;
    }
  }
}

.chart-container {
  position: relative;
}

.app-table-hover > tbody > tr:hover {
  background-color: $default-hover-color;
}

.app-card-notification {
  .notification-type {
    .badge {
      font-size: 0.65rem;
      text-transform: uppercase;
    }
  }
  .profile-image {
    width: 60px;
    height: 60px;
  }
  .notification-title {
    font-size: 1.125rem;
  }

  .notification-content {
    font-size: 0.875rem;
  }

  .notification-meta {
    font-size: 0.75rem;
    color: $theme-text-color-light;
  }

  .action-link {
    font-size: 0.875rem;
  }

  .app-card-footer,
  .card-footer {
    background: mix($theme-bg-light, white, 50%);
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .table-search-form {
    .form-control {
      min-width: 300px;
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .app-card-stat .stats-figure {
    font-size: 1.125rem;
  }
  .app-card-stat .stats-type {
    font-size: 0.75rem;
  }
}

.accordion-button {
  &:not(.collapsed) {
    box-shadow: none;
  }
}

.avatar {
  flex-shrink: 0;
  border-radius: 50%;
  border: none;
  background-color: rgb(255, 255, 255);
  width: 100%;
}

.back {
  border-radius: 50%;
  border: none;
  background-color: transparent;
  height: 28px;
  width: 28px;
  margin-right: 12px;

  &:hover {
    background-color: $white;
  }
}

.back-icon {
  width: 1rem;
  height: 1rem;
  margin-left: 2px;
}

.disabled-authentication-warning-width {
  width: auto;
}

// Added by PDFjs, has 22px height by default and is not needed
.hiddenCanvasElement {
  position: absolute;
  display: none;
}
