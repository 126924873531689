.menu {
  height: 100%;
  border-top: 1px solid $theme-bg-light;
  box-shadow: 4px 6px 36px 8px rgba(120, 129, 214, 0.04);

  .menu-title {
    font-size: 1.2em;
    margin-left: 10px;
    top: 5px;
  }

  .app-nav {
    margin-bottom: 15px;
    background: $white;
  }
}

.menu-inner {
  width: 100%;
  height: 100%;
  background: $white;
}

.menu-content {
  margin-top: 50px;
  margin-left: 0px;
}
