.app-focus {
  padding-top: 0px;
  background-color: $theme-bg-light;

  @media (min-width: 1200px) {
    .app-wrapper {
      margin-left: 0px;
      padding-left: 240px;
      padding-right: 240px;
    }
  }
  .app-page-title,
  .page-title {
    font-size: 2em;
    font-weight: 400;
  }
}
