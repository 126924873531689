$bg-color: #0B225B;
$hover-color: #05236D;
$submenu-hover-color: #162C62;
$active-color: #243B74;

.app-sidepanel {
  height: 100%;
  background: $bg-color;

  .sidepanel-inner {
    @include box-shadow(rgb(0 0 0 / 2%) 0 .25rem .25rem);
    width: $sidepanel-width;
    height: 100%;
  }
}

.app-branding {
  padding: 1.125rem 1.25rem 1.125rem 1.25rem;

  .logo-icon {
    height: 24px;
    width: 24px;
  }

  .logo-text {
    color: $theme-text-color-primary;
    font-size: 1.25rem;
    font-weight: 600;
    vertical-align: middle;
  }
}

.app-nav {
  color: $theme-text-color-secondary;
  display: block;
  padding: 0;
  width: 100%;

  .nav-item {
    display: block;
    color: $theme-text-color-secondary;

    &:hover {
      background: $hover-color;

      a {
        i {
          color: $green-900;
        }
      }
    }
    &.active {
      background: $active-color;

      a {
        i {
          color: $green-500;
        }
      }
    }
  }

  .nav-link {
    display: flex;
    color: $white;
    justify-content: center;
    align-items: center;
    height: 50px;
  }
}

.app-nav-main {
  .nav-item.nav-secondary-item {
    background-color: $white;
    border-right: 4px solid transparent;
    color: $theme-text-color-primary;
    size: 14px;

    &:hover {
      a {
        color: $blue;
      }
    }
    &.active {
      background: $blue-light !important;
      border-right: 4px solid $blue-500;
    }
  }
}

