@import '~stylesheets/variables';

.ul {
  font-size: $h5-font-size !important;
}

.ol1 {
  padding: 0;
  margin: 0;
  list-style-position: outside;
}

.ol2 {
  padding: 0;
  margin: 0;
  list-style-type: upper-alpha;
  list-style-position: outside;
}

.ol3 {
  padding: 0;
  margin: 0;
  list-style-type: lower-alpha;
  list-style-position: outside;
}

.ol4 {
  padding: 0;
  margin: 0;
  list-style-type: upper-roman;
  list-style-position: outside;
}

.ol5 {
  padding: 0;
  margin: 0;
  list-style-type: lower-roman;
  list-style-position: outside;
}

.ul {
  padding: 0;
  margin: 0px 0px 0px map-get($spacers, 2h);
  list-style-position: outside;
  font-size: $h5-font-size !important;
}

.list-item {
  margin: 0px 0px 0px map-get($spacers, 2h);
}

.nested-list-item {
  list-style-type: none;
}

.nested-list-item:before,
.nested-list-item:after {
  display: none;
}
