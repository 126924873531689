.nav.nav-pills {
  .nav-item {
    .nav-link,
    .nav-text {
      border: 1px solid $nav-pills-border-color;
      border-radius: 0;
      border-right: 0;
    }
  }

  .nav-item:first-child {
    .nav-link,
    .nav-text {
      border-radius: $nav-tabs-border-radius 0 0 $nav-tabs-border-radius;
    }
  }

  .nav-item:last-child {
    .nav-link,
    .nav-text {
      border-radius: 0 $nav-tabs-border-radius $nav-tabs-border-radius 0;
      border-right: 1px solid $nav-pills-border-color;
    }
  }
}
