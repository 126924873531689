@import '~bootstrap/scss/functions';

// =============================================================================
// CUSTOM VARIABLES
// =============================================================================
//
// Variables that we need which DON'T exist in Bootstrap.

// BOX SHADOW CUSTOM COLOR

$box-shadow-color: #7881d6;

// SIZING

$height-sm: 1.5rem;
$height-md: 2.25rem;
$height-lg: 2.625rem;

/*************************** Theme Colours****************************/
// Define theme colour scheme
$white: #ffffff;

$dark-primary: #08243e;
$dark-200: #5e718f;
$dark-500: #344965;
$dark-sidebar: #223c54;
$dark-blue-200: #243B74;
$dark-blue-500: #0B225B;

$blue: #5348fe;
$blue-200: #9daaf0;
$blue-500: #7976ff;
$blue-900: #322693;
$blue-gray-200: #eaeff8;
$blue-gray-500: #d6deec;
$blue-gray-700: #bfcadd;
$blue-gray-900: #899cb9;
$blue-gray-light: #f3f8ff;
$blue-light: #f6f6ff;

$gray: #f6f8fa;
$gray-300: #dde4ea;
$gray-700: #a7afb6;
$gray-900: #646f79;
$gray-dark: #787CA3;

$green: #038310;
$green-500: #4ae5d2;
$green-700: #21d9bd;
$green-900: #18c0a2;
$green-light: #f2fdfb;
$green-success: #11a520;

$success-500: #40c74d;
$success-light: #f6fcf6;

$orange: #ffad62;
$orange-500: #fbc08b;
$orange-light: #fff7f0;

$pink: #ff5dbe;
$pink-500: #ec73bb;
$pink-light: #fff1f9;

$red: #c02e30;
$red-200: #E9797B;
$red-500: #E76A6B;
$red-light: #fef6f6;

$yellow: #FFD954;
$yellow-500: #FFE898;
$yellow-light: #FFFAE8;

$theme-color-primary: $dark-primary;
$theme-color-secondary: #29f3da;
$theme-text-color-primary: $theme-color-primary;
$theme-text-color-secondary: $theme-text-color-primary;
$theme-text-color-light: $gray-700;
$theme-bg-light: $gray;
$theme-border-color: $gray-300;
$theme-color-new: #5ab66e;
$theme-color-count: $green-900;
$theme-error: $red;
$smoky-white: #fafdff;

$theme-success-color: $green-success;
$theme-warning-color: $orange;
$theme-info-color: $blue;
$theme-danger-color: $red;
$theme-pin-color: $blue-500;
$theme-note-color: #bed8ff;
$theme-light-info: $blue-light;

$theme-light-color: $white;
$theme-dark-color: $theme-color-primary;

$theme-color-pdf: #da2d27;
$theme-color-word: #66a0fd;
$theme-color-ppt: #f4b400;
$theme-color-excel: #0da95f;
$theme-color-video: #935dc1;
$theme-color-zip: #252930;

$default-hover-color: #f3faff;

$table-hover-bg-factor: 0.03;
$table-hover-bg: $default-hover-color;
$table-group-separator-color: $theme-border-color;

$nav-link-color: $theme-color-primary;
$nav-tabs-link-active-bg: $theme-color-primary;
$nav-pills-border-color: #d3d5f2;
$component-active-bg: $theme-color-primary;

$text-muted: $gray-700;

$rating-color: #ffd954;

// CHART COLORS

$chart-green-500: $green-500;
$chart-pink-500: $pink-500;
$chart-blue-500: $blue-500;
$chart-orange-500: $orange-500;
$chart-red-500: $red-500;

/*************************** Variables Section. *****************************/
// Create variables to override the default value for variables used in the Bootstrap SCSS files.

$tooltip-bg: rgba(8, 36, 62);
$tooltip-max-width: 250px;

$theme-colors: (
  'blue': $blue,
  'blue-200': $blue-200,
  'blue-500': $blue-500,
  'blue-900': $blue-900,
  'blue-gray-200': $blue-gray-200,
  'blue-gray-500': $blue-gray-500,
  'blue-gray-700': $blue-gray-700,
  'blue-gray-900': $blue-gray-900,
  'blue-gray-light': $blue-gray-light,
  'chart-red-500': $chart-red-500,
  'danger': $theme-danger-color,
  'danger-500': $red-500,
  'dark': $theme-dark-color,
  'dark-200': $dark-200,
  'dark-500': $dark-500,
  'dark-sidebar': $dark-sidebar,
  'gray': $gray,
  'gray-300': $gray-300,
  'gray-700': $gray-700,
  'gray-900': $gray-900,
  'green-500': $green-500,
  'green-700': $green-700,
  'green-900': $green-900,
  'green-light': $green-light,
  'info': $theme-info-color,
  'info-500': $blue-500,
  'light': $theme-light-color,
  'light-danger': $red-light,
  'light-info': $blue-light,
  'light-success': $success-light,
  'light-warning': $orange-light,
  'note': $theme-note-color,
  'orange': $orange,
  'pin': $blue-500,
  'pink-light': $pink-light,
  'pink': $pink,
  'primary': $theme-color-primary,
  'rating-color': $rating-color,
  'red-500': $red-500,
  'secondary': $theme-text-color-secondary,
  'success': $theme-success-color,
  'success-500': $success-500,
  'warning': $theme-warning-color,
  'yellow': $yellow,
  'yellow-500': $yellow-500,
  'yellow-light': $yellow-light,
);

$sidepanel-width: 64px;
$link-hover-color: darken($theme-color-primary, 5%);

$font-family-sans-serif: 'Nunito Sans', system-ui, -apple-system, 'Segoe UI',
  Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-weight-bold: 700;
$font-weight-semibold: 600;
$font-weight-regular: 400;

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * 0.75;
$font-size-lg: $font-size-base * 1.75;
$small-font-size: $font-size-sm;

$display1-font-size: $font-size-base * 2;
$display2-font-size: $font-size-base * 1.75;
$h1-font-size: $font-size-base * 1.5;
$h2-font-size: $font-size-base * 1.25;
$h3-font-size: $font-size-base * 1.125;
$h4-font-size: $font-size-base;
$h5-font-size: $font-size-base * 0.875;
$h6-font-size: $font-size-base * 0.75;
$h7-font-size: $font-size-base * 0.5;
$paragraph-font-size: $font-size-base;
$form-text-font-size: $font-size-base;
$input-font-size: $font-size-base;
$form-label-font-size: $font-size-base * 0.875;
$form-label-font-weight: $font-weight-regular;
$form-check-label-disabled-opacity: 1;
$badge-padding-y: 0.25rem;
$badge-padding-x: 0.75rem;

// Accordion
$accordion-padding-y:                     1.5rem;
$accordion-padding-x:                     1.5rem;
$accordion-color:                         $dark-primary;
$accordion-bg:                            $white;
$accordion-border-width:                  1px;
$accordion-border-color:                  $blue;
$accordion-border-radius:                 12px;

$accordion-body-padding-y:                0rem;
$accordion-body-padding-x:                $accordion-padding-x;

$accordion-button-padding-y:              $accordion-padding-y;
$accordion-button-padding-x:              $accordion-padding-x;
$accordion-button-color:                  $dark-primary;
$accordion-button-bg:                     $accordion-bg;
$accordion-button-active-bg:              $white;
$accordion-button-active-color:           $dark-primary;

$accordion-button-focus-border-color:     none;
$accordion-button-focus-box-shadow:       none;

$accordion-icon-width:                    1rem;
$accordion-icon-color:                    $blue;
$accordion-icon-active-color:             $accordion-icon-color;

// Alert
$alert-dismissible-padding-r: 1rem;
$alert-padding-y: .625rem;
$alert-padding-x: 1.25rem;

// Border Radius
$border-radius-1: 4px;
$border-radius-2: 8px;
$border-radius-3: 12px;
$border-radius-4: 16px;
$border-radius-5: 24px;

// Button
$btn-link-color: $theme-color-primary;
$btn-padding-y: 8px;
$btn-padding-y-lg: 12px;
$btn-padding-y-sm: 4px;
$btn-padding-x: 12px;
$btn-padding-x-lg: 16px;
$btn-padding-x-sm: 8px;

$btn-font-size-sm: $small-font-size;
$btn-font-size: $form-label-font-size;
$btn-font-size-lg: $font-size-base;

$btn-focus-width: 1.5px;
$btn-active-box-shadow: none;
$active-background: $blue;
$active-color: $white;
$btn-focus-box-shadow: inset 0px 0px 0px 1.5px $blue-900;

$btn-close-width: 12px;
$btn-close-height: 12px;
$btn-close-padding-y: 2px;
$btn-close-padding-x: 2px;
$btn-close-color: $dark-primary;
$btn-close-focus-shadow: none;
$btn-close-hover-opacity: 1;

$btn-border-radius: .25rem;
$btn-border-radius-sm: $btn-border-radius;
$btn-border-radius-lg: $btn-border-radius;
$btn-link-color: $blue;
$link-decoration: none;
$btn-link-hover-color: $blue;
$link-hover-decoration: none;

$dropdown-link-active-color: none;
$dropdown-link-active-bg: none;
$dropdown-link-hover-bg: rgba($theme-note-color, .1);

$-disabled-opacity: 1;
$form-check-input-focus-box-shadow: none;

// Offcanvas
$offcanvas-padding-y:               24px;
$offcanvas-padding-x:               24px;
$offcanvas-horizontal-width:        400px;
$offcanvas-vertical-height:         30vh;
$offcanvas-transition-duration:     .3s;
$offcanvas-bg-color:                $white;

$modal-content-inner-border-radius: 24px;
$modal-inner-padding: 24px;
$modal-footer-margin-between: 16px;
$modal-footer-border-width: 0px;
$modal-header-border-width: 0px;
$modal-dialog-margin-y-sm-up: 50px;

$font-sizes: (
  display1: $display1-font-size, // 32px
  display2: $display2-font-size, // 28px
  1: $h1-font-size, // 24px
  2: $h2-font-size, // 20px
  3: $h3-font-size, // 18px
  4: $h4-font-size, // 16px
  5: $h5-font-size, // 14px
  6: $h6-font-size, // 12px
  7: $h7-font-size, // 8px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 1280px,
  xl: 1440px,
  xxl: 1680px,
);

@import '~bootstrap/scss/variables';

$spacers: map-merge(
  $spacers,
  (
    1h: $spacer * 0.5,
    2h: $spacer * 0.75,
    3h: $spacer * 1.25,
    4h: $spacer * 1.75,
    5h: $spacer * 2,
    6h: $spacer * 2.25,
    6: $spacer * 4,
    7: $spacer * 5,
    8: $spacer * 6,
    10: $spacer * 8,
  )
);

$gutters: $spacers;

$app-header-height: 3.75rem;
