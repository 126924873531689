@import '~stylesheets/variables';

.candidateSurveyRequestPage {
  background-color: white;
}

.referencesCard {
  background-color: white;
  border-radius: 24px;
}
