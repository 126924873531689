/* Header */
.app-header {
  @include box-shadow(rgb(0 0 0 / 2%) 0 .25rem .25rem);
  border-bottom: 1px solid $theme-border-color;
  height: $app-header-height;
  background: $white;
}

.app-header-inner {
  position: relative;
}

.app-search-form {
  max-width: 400px;
}

.app-utility-item {
  display: inline-block;
  margin-right: 1.25rem;
  &:last-child {
    margin-right: 0;
  }
  & > a {
    color: $theme-text-color-secondary;
    &:hover {
      color: $theme-text-color-primary;
    }
  }
  .dropdown-toggle {
    position: relative;
  }
  .dropdown-menu {
    font-size: 0.875rem;
    margin: 0;
  }
  .dropdown-menu.show {
    top: 1.5rem !important;
  }
  .icon {
    font-size: 1.25rem;
    width: 2rem;
    height: 2rem;
  }
  .icon-badge {
    display: inline-block;
    @include border-radius(50%);
    position: absolute;
    right: -0.45rem;
    top: -0.3rem;
    width: 1rem;
    height: 1rem;
    font-size: 0.6rem;
    font-weight: bold;
    padding-top: 1px;
    color: $white;
    text-align: center;
    &.icon-badge {
      background: $theme-color-count;
      border: 2px solid $white;
    }
  }
}

.app-notifications-dropdown {
  .dropdown-menu {
    width: 300px;
    .dropdown-menu-header {
      border-bottom: 1px solid $theme-border-color;
    }
    .dropdown-menu-title {
      font-size: 1rem;
    }
    .profile-image {
      width: 36px;
      height: 36px;
    }
    .info {
      padding-right: 80px;
      position: relative;
    }
    .meta {
      color: $theme-text-color-light;
      position: absolute;
      right: 0;
      top: 0;
      font-size: 0.75rem;
    }
    .dropdown-menu-footer {
      border-top: 1px solid $theme-border-color;
    }

    .item {
      min-width: 250px;
      position: relative;
      border-bottom: 1px solid $theme-border-color;
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background: $smoky-white;
      }
    }

    .link-mask {
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      left: 0;
      top: 0;
    }
  }

  .app-icon-holder {
    display: inline-block;
    background: lighten($theme-color-primary, 60%);
    color: $theme-color-primary;
    width: 36px;
    height: 36px;
    padding-top: 4px;
    font-size: 1rem;
    text-align: center;
    @include border-radius(50%);

    &.icon-holder-mono {
      background: $theme-bg-light;
      color: $theme-text-color-light;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.app-user-dropdown {
  display: inline-block;

  img,
  .avatar {
    border: 2px solid $blue-gray-200;
    width: 36px;
    height: 36px;

    &:hover {
      border-color: $blue-gray-500;
    }
  }
}
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .app-notifications-dropdown .dropdown-menu {
    width: 400px;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .app-header-inner {
    margin-left: $sidepanel-width;
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
	.search-mobile-trigger {
		cursor: pointer;
		@include opacity(0.8);
		&:hover {
			color: $theme-color-primary;
			@include opacity(1);
		}

	}
	.app-search-box {
		display: none;
		position: absolute;
	    left: 0;
	    top: 56px;
	    padding: 0;
	    height: 100vh;
	    background: rgba(0, 0, 0, 0.5);
	    @include transition (all 0.4s ease-in-out);

	    &.is-visible {
		    display: block;
	    }
	}
}
