.cronofy-common {
  .body {
    background-color: #f6f8fa;
    height: 100%;
    margin: 1.5rem;
  }

  .wrapper {
    margin: 0 auto;
    max-width: 1200px;
  }

  .header {
    align-items: center;
    display: flex;
    margin-bottom: 24px;
    width: 100%;
  }

  .back-arrow {
    margin-right: 0;
  }

  .current-schedule {
    margin-right: 24px;
  }

  .footer {
    margin-top: 24px;
  }

  .footer p {
    color: #5E718F;
    font-size: 14px;
    text-align: center;
  }
}

.cronofy-schedule {
  .cronofy {
    font-size: 12px !important;
  }

  .cronofy .cronofy__details {
    display: none !important;
  }

  .cronofy .cronofy__calendar-header--title {
    font-size: 18px !important;
  }

  .cronofy .cronofy__time-slots-list--header {
    font-size: 18px !important;
  }

  .cronofy .cronofy__calendar-grid--week-day {
    color: #08243E;
  }

  .cronofy .cronofy__calendar-grid--available {
    background-color: white !important;
    color: #08243E !important;
  }

  .cronofy .cronofy__time-slots-group--wrapper {
    max-height: 314px !important;
    overflow-y: scroll !important;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    overflow: scroll;
    scrollbar-width: none;  /* Firefox */
  }

  .cronofy .cronofy__time-slots-group--wrapper::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }

  .cronofy .cronofy__calendar-grid--available {
    font-weight: 600 !important;
  }

  .cronofy .cronofy__calendar-grid--selected {
    background-color: #08243E !important;
    color: white !important;
    font-weight: 600 !important;
  }

  .cronofy .cronofy__time-slot span {
    color: #08243E;
    font-size: 12px;
    font-weight: 600 !important;
  }

  .cronofy .cronofy__time-slot button {
    padding: 0.75rem 0;
  }

  .DTP .DTP__time-slot {
    height: 100%;
    border-radius: 6px;
    font-size: 14px;
  }

  .DTP__time-slots-list > * + *, .DTP__slot-list > * + * {
    margin-top: 12px;
  }

  .cronofy .cronofy__time-slot:hover span {
    color: white;
  }

  .cronofy .cronofy__confirm-details {
    background-color: #F6F6FF;
    border-radius: 24px;
    padding: 28px;
  }

  .cronofy .cronofy__confirm-details--info {
    font-size: 18px !important;
  }

  .cronofy .cronofy__confirm-button {
    background-color: #5348FE !important;
    border: none !important;
    box-shadow: none !important;
    color: white !important;
  }

  .cronofy .cronofy__confirm-button:hover {
    background-color: #473dd8 !important;
    border: none !important;
    box-shadow: none !important;
    color: white !important;
  }

  .cronofy .cronofy__cancel-button {
    color: #08243E !important;
  }

  .cronofy .cronofy__cancel-button:hover {
    background-color: white !important;
    box-shadow: none !important;
    color: #08243E !important;
  }
}

.cronofy-self-schedule {
  .cronofy .cronofy__confirm-button {
    display: inline-block;
    margin-left: 0px;
    min-width: auto;
  }

  .cronofy .cronofy__confirm-button:hover {
    background-color: #7976FF;
  }

  .cronofy .cronofy__cancel-button {
    display: inline-block;
    margin-left: 20px;
  }

  .cronofy .cronofy__time-slot {
    background: white;
    border-color: #5348FE;
  }

  .cronofy .cronofy__time-slot:hover {
    background: #F6F6FF;
    border-color: #5348FE;
    box-shadow: none;
  }

  .cronofy .cronofy__time-slot:focus {
    background: #F6F6FF;
    border-color: #5348FE;
    box-shadow: none;
  }

  .cronofy .cronofy__calendar-grid--available {
    color: #5348FE !important;
    border-color: #5348FE;
    font-weight: 600 !important;
  }
  .cronofy .cronofy__calendar-grid--available:hover {
    background: #F6F6FF !important;
    border-color: #5348FE;
    box-shadow: none;
  }
  .cronofy .cronofy__calendar-grid--selected {
    background-color: #5348FE !important;
    border-color: #5348FE;
    box-shadow: none;
    color: white !important;
    font-weight: 600 !important;
  }
  .cronofy .cronofy__calendar-grid--selected:hover {
    background-color: #5348FE !important;
    border-color: #5348FE;
    box-shadow: none;
    color: white !important;
    font-weight: 600 !important;
  }
  .cronofy .cronofy__time-slot span {
    color: #5348FE;
    font-weight: 600 !important;
  }
  .cronofy .cronofy__time-slot:hover span {
    color: #5348FE;
    font-weight: 600 !important;
  }
}

.cronofy-share-availability {
  .cronofy .cronofy__time-slot {
    background: #F6F6FF;
    border: none;
    box-shadow: none;
    pointer-events: none;
  }
  .cronofy .cronofy__time-slot:focus {
    border: none;
    box-shadow: none;
  }
  .cronofy .cronofy__time-slot span {
    color: #322693;
  }
  .cronofy .cronofy__calendar-grid--available {
    background-color: #F6F6FF !important;
    border: none;
    box-shadow: none;
    color: #322693 !important;
  }
  .cronofy .cronofy__calendar-grid--available:hover {
    border: none;
    box-shadow: inset #9DAAF0 0 0 0 .1em;
  }
  .cronofy .cronofy__calendar-grid--selected {
    background-color: #F6F6FF !important;
    border: none;
    box-shadow: inset #9DAAF0 0 0 0 .1em !important;
    color: #322693 !important;
  }
}

.cronofy-feedback {
  .content {
    background-color: #FFFFFF;
    border-radius: 24px;
    margin: 36px 0;
    padding: 48px;
    vertical-align: top;
  }

  .image {
    margin: 36px 0;
    text-align: center;
  }

  .message p {
    font-size: 16px;
    text-align: center;
  }

  .button {
    background-color: #3869D4;
    border-top: 10px solid #3869D4;
    border-right: 18px solid #3869D4;
    border-bottom: 10px solid #3869D4;
    border-left: 18px solid #3869D4;
    display: inline-block;
    color: #FFF;
    text-decoration: none;
    border-radius: 3px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
    -webkit-text-size-adjust: none;
    box-sizing: border-box;
  }

  @media only screen and (max-width: 500px) {
    .button {
      width: 100% !important;
      text-align: center !important;
    }
  }

  .action-button {
    background-color: #5348FE;
    border-color: #5348FE;
    color: #FFF;
  }

  .action-button:hover {
    color: #FFF;
  }

  .action-button-table {
    margin-left: auto;
    margin-right: auto;
  }
}
