.table-responsive {
  padding-top: 1.5rem;
}

.table-responsive-footer {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1rem;

  .page-entries {
    color: $dark-200;
  }
}

.left-col {
  padding-left: 1.5rem !important;
}

.right-col {
  width: 2.25rem;
  padding-right: 1.5rem !important;
}

.table-responsive table {
  overflow-y: visible !important;
}

.table > :not(:first-child) {
  border-top: inherit;
}

.table > :last-child {
  border-bottom: none;
}
