/* ============= Base ============= */

body {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: grayscale;
  color: $theme-text-color-secondary;
}

h1,
h2,
h3,
h4 {
  color: inherit;
}

h1,
h2 {
  font-weight: $font-weight-bold;
}

h3 {
  font-weight: $font-weight-semibold;
}

h4 {
  font-weight: $font-weight-bold;
}

h5 {
  font-weight: $font-weight-semibold
}

a {
  color: $theme-color-primary;
  text-decoration: none;
  cursor: pointer;

  &.disabled {
    opacity: 0.5;
  }
}

.login-page-link {
  color: $blue;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $blue;
  }
}

.display1 {
  font-size: 2rem;
  font-weight: $font-weight-bold;
}

.display2 {
  font-size: 1.75rem;
  font-weight: $font-weight-semibold;
}

.display3 {
  font-size: 0.875rem;
  font-weight: $font-weight-regular;
}

label {
  font-size: inherit;
}

.table-dark {
  background: $theme-text-color-primary;
  color: $white;
}

.table-bordered th,
.table-bordered td {
  border-color: $theme-border-color;
}

.table th,
.table td {
  border-color: $theme-border-color;
}

.table thead th {
  border-color: $theme-border-color;
  font-weight: $font-weight-semibold;
  font-size: $h5-font-size;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: $white;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: $theme-border-color;
}

.table-hover > tbody > tr:hover {
  background-color: $default-hover-color;
}

.popover {
  border-color: darken($theme-bg-light, 5%);
}
.bs-popover-left > .arrow::before,
.bs-popover-auto[x-placement^='left'] > .arrow::before {
  border-left-color: darken($theme-bg-light, 5%);
}
.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^='right'] > .arrow::before {
  border-right-color: darken($theme-bg-light, 5%);
}
.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^='top'] > .arrow::before {
  border-top-color: darken($theme-bg-light, 5%);
}
.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^='bottom'] > .arrow::before {
  border-bottom-color: darken($theme-bg-light, 5%);
}

a.app-link {
  color: $theme-text-color-primary;
  text-decoration: underline;
  -webkit-text-decoration-color: rgba($theme-text-color-primary, 0.3);
  text-decoration-color: rgba($theme-text-color-primary, 0.3);
  &:hover {
    color: $theme-color-primary;
    -webkit-text-decoration-color: rgba($theme-color-primary, 0.8);
    text-decoration-color: rgba($theme-color-primary, 0.8);
  }
}

button.close {
  &:focus {
    outline: none;
    @include box-shadow(none);
  }
}

.form-control {
  @include placeholder($dark-200 !important);

  &::placeholder {
    font-size: 14px;
  }

	&[type=text] {
		height: 36px;
	}
  &[type=number] {
		height: 36px;
	}
  &[disabled],
  &:disabled {
    @include placeholder($gray-700);

    border-color: $gray-300 !important;
    background-color: $gray !important;
    color: $gray-700 !important;
  }

	border-color: $blue-gray-500;
  color: $dark-500;
  &:hover {
    color: $dark-500;
    border-color: $gray-900;
  }
  &:focus {
    @include box-shadow(none);
    outline: none;
    color: $dark-500;
    border-color: $blue;
  }
  &.form-border-danger {
    @include placeholder($red-500);
    border-color: $red-500;
  }
}

.form-check-input {
  border-color: $blue !important;
  min-width: 1em;

  &:checked {
    background-color: $blue;
  }

  &[disabled],
  &:disabled {
    border-color: $blue-200 !important;

    &:checked {
      background-color: $blue-200;
    }
    ~ .form-check-label {
      color: $dark-200;
    }
  }
}

.search-input {
	font-size: $form-label-font-size;
	@include border-radius(1.25rem);
	background-image: url('../images/icons/search.svg');
	background-repeat: no-repeat;
	background-position-x: 16px;
	background-position-y: center;
	padding-right: 1rem;
	padding-left: 40px;
	height: $height-md;
	color: $dark-500;
	border-color: $blue-gray-500;
	&:hover {
		color: $dark-500;
		border-color: $gray-900;
	}
	&:focus {
		color: $dark-500;
		border-color: $blue;
	}
}

.dropdown-menu {
  border-color: $theme-border-color;

  .dropdown-item {
     padding: 0.5rem 1rem;
  }
}

.dropdown-divider {
  border-top-color: $theme-border-color;
  @include opacity(1);
}

.dropdown-toggle.no-toggle-arrow {
  &:after {
    display: none !important;
  }
}
