.btn {
  border: none !important;
}

.btn,
.btn-md {
  line-height: 20px;
}

.btn-lg {
  line-height: 24px;
}

.btn-sm {
  line-height: 16px;
}

.btn-info,
.btn-primary {
  background-color: $blue;
  color: $white !important;

  &:focus {
    background-color: $blue;
    box-shadow: inset 0px 0px 0px 1.5px $blue-900 !important;
  }

  &:hover {
    background-color: $blue-500;
  }

  &:active {
    background-color: $blue-900;
    box-shadow: inset 0px 0px 0px 1px $blue-900;

    &:focus {
      background-color: $blue-900;
      box-shadow: inset 0px 0px 0px 1px $blue-900;
    }
  }

  &:disabled {
    background-color: $blue-200;
  }
}

.btn-secondary {
  background-color: $white;
  box-shadow: inset 0px 0px 0px 1px $blue;
  color: $blue;

  &:focus {
    background-color: $blue-light;
    box-shadow: inset 0px 0px 0px 1.5px $blue-900 !important;
    color: $blue;
  }

  &:hover {
    background-color: $blue-light;
    color: $blue;
  }

  &:active {
    background-color: $blue-light;
    box-shadow: inset 0px 0px 0px 1px $blue-900 !important;
    color: $blue-900;

    &:focus {
      background-color: $blue-light;
      box-shadow: inset 0px 0px 0px 1px $blue-900 !important;
      color: $blue-900;
    }
  }

  &:disabled {
    background-color: $white;
    box-shadow: inset 0px 0px 0px 1px $blue-200 !important;
    color: $blue-200;
  }
}

.btn-borderless {
  background-color: transparent;
  box-shadow: none !important;
  color: $blue;

  &:focus {
    background-color: $blue-light;
    color: $blue;
  }

  &:hover {
    background-color: $blue-light;
    color: $blue;
  }

  &:active {
    background-color: $blue-light;
    box-shadow: inset 0px 0px 0px 1px $blue-900;
    color: $blue-900;

    &:focus {
      background-color: $blue-light;
      box-shadow: inset 0px 0px 0px 1px $blue-900;
      color: $blue-900;
    }
  }

  &:disabled {
    background-color: transparent;
    color: $blue-200;
  }
}

.btn-success {
  color: $white !important;
  background-color: $green-success;

  &:focus {
    box-shadow: inset 0px 0px 0px 1.5px $green-success;
  }

  &:hover {
    background-color: $success-500;
  }

  &:active {
    background-color: #00680B;
    box-shadow: inset 0px 0px 0px 1px #00680B;

    &:focus {
      background-color: #00680B;
      box-shadow: inset 0px 0px 0px 1px #00680B;
    }
  }

  &:disabled {
    background-color: #78CF80;
  }
}

@mixin btn-danger-base {
  background-color: $white;
  color: $red;

  &:focus {
    background-color: $red-light;
    color: $red;
  }

  &:hover {
    background-color: $red-light;
    color: $red;
  }

  &:active {
    background-color: $red-light;
    color: #8D0D0F;

    &:focus {
      background-color: $red-light;
      color: #8D0D0F;
    }
  }

  &:disabled, &.disabled {
    background-color: $white;
    box-shadow: inset 0px 0px 0px 1px #E9797B;
    color: #E9797B;
  }
}

.btn-danger {
  @include btn-danger-base;
  box-shadow: inset 0px 0px 0px 1px $red;

  &:focus {
    box-shadow: inset 0px 0px 0px 1.5px #8D0D0F;
  }

  &:active {
    box-shadow: inset 0px 0px 0px 1px #8D0D0F;

    &:focus {
      box-shadow: inset 0px 0px 0px 1px #8D0D0F;
    }
  }
}

.btn-danger-borderless {
  @include btn-danger-base;
  box-shadow: none;
}

.btn-check:checked + .btn-primary {
  background-color: $blue;

  &:focus {
    box-shadow: inset 0px 0px 0px 1.5px $blue-900;
  }
}

.btn-check:focus + .btn-primary {
  background-color: $blue;
  box-shadow: inset 0px 0px 0px 1.5px $blue-900;
}

.btn-check:active + .btn-primary {
  background-color: $blue;
}

.btn-check:checked + .btn-secondary {
  background-color: $white;
  color: $blue;
}

.btn-check:focus + .btn-secondary {
  background-color: $white;
  color: $blue;
  box-shadow: inset 0px 0px 0px 1.5px $blue-900;
}

.btn-check:active + .btn-secondary {
  background-color: $white;
  color: $blue
}

.btn-close {
  opacity: 0.8;
}

.btn-google {
  background-color: $theme-bg-light;
  &:hover {
    background-color: $blue-light;
  }
}
