/* ============= Theme Mixins ============= */
@mixin opacity($opacity: 0.5) {
  -webkit-opacity: $opacity;
  -moz-opacity: $opacity;
  opacity: $opacity;
}

@mixin scale($factor) {
  -webkit-transform: scale($factor);
  -moz-transform: scale($factor);
  -ms-transform: scale($factor);
  -o-transform: scale($factor);
}

@mixin background-size($string: contain) {
  -webkit-background-size: $string;
  -moz-background-size: $string;
  -o-background-size: $string;
  background-size: $string;
}

@mixin reset-box-sizing($size: content-box) {
  &,
  *,
  *:before,
  *:after {
    -webkit-box-sizing: $size;
    -moz-box-sizing: $size;
    box-sizing: $size;
  }
}

@mixin placeholder($color: #999) {
  &::-webkit-input-placeholder {
    // WebKit browsers
    color: $color;
  }
  &::-moz-placeholder {
    // Mozilla Firefox
    color: $color;
  }
  &:-ms-input-placeholder {
    // Internet Explorer 10+
    color: $color;
  }
}

@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

// generic transform
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}
// rotate
@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
  @include transform(scale($scale));
}
// translate
@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}
// skew
@mixin skew($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}
//transform origin
@mixin transform-origin($origin) {
  moz-transform-origin: $origin;
  -o-transform-origin: $origin;
  -ms-transform-origin: $origin;
  -webkit-transform-origin: $origin;
  transform-origin: $origin;
}
