.chip {
  @extend .btn,
    .btn-sm,
    .border,
    .border-info,
    .fw-normal,
    .me-2,
    .mt-2,
    .text-truncate,
    .rounded-pill,
    .text-info;
  max-width: 180px;
  opacity: 1 !important;
}

.rounded-pill {
  line-height: 1rem;
  padding-bottom: .25rem;
  padding-top: .25rem;
}

.chip--selected {
  @extend .btn-info, .bg-info, .text-white;
}
