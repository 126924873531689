.app-signup {
  .auth-background-holder {
    background: url('../images/background/background-2.jpg') no-repeat center
      center;
    @include background-size(cover);
    height: 100vh;
    min-height: 100%;
  }
}

.app-reset-password {
  .auth-background-holder {
    background: url('../images/background/background-3.jpg') no-repeat center
      center;
    @include background-size(cover);
    height: 100vh;
    min-height: 100%;
  }
}

.auth-main-col,
.auth-background-col {
  position: relative;
}

.auth-right-section {
  position: relative;
  background-image: url('../images/background/background-login-2.png');
  background-size: cover;
  background-position: center;
}

.container-background-text {
  width: 100%;
  height: 90%;
  margin: 0 auto;
  position: absolute;
}

.auth-background-mask {
  background: $blue-900;
}

.app-auth-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.app-auth-wrapper {
  background: $white;
  height: 100vh;

  .app-auth-body {
    width: 360px;
    &.app-auth-new-sessions {
      // width: 100%;
    }
  }

  .app-logo {
    .logo-icon {
      width: 100%;
      max-width: 200px;
    }
  }
  .auth-heading {
    font-size: 1.5rem;
  }

  .form-control {
    @include placeholder($dark-500);
  }

  .legal-note {
    font-size: 0.875rem;
  }

  .extra {
    font-size: 0.75rem;
    a {
      text-decoration: underline;
      color: $theme-text-color-light;
      &:hover {
        color: $theme-text-color-secondary;
      }
    }
  }

  .auth-heading-desc {
    font-weight: 500;
    font-size: 1.125rem;
  }

  .legal-note {
    font-size: 0.75rem;
  }

  .auth-option {
    font-size: 0.875rem;
  }
}

.app-auth-footer {
  .copyright {
    font-size: 0.75rem;
  }
}

.auth-text-container {
  font-size: 54px;
  line-height: 54px;
}

.form-container {
  margin: auto;
  width: 324px;
}

.forgot-password {
  a {
    text-decoration: underline;
  }
  a:hover {
    color: $theme-color-primary !important;
  }
}

@include media-breakpoint-down(xxxl) {
  .auth-text-container {
    font-size: 48px;
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .auth-background-col {
    display: none;
  }

  .app-auth-wrapper .app-auth-body {
    width: 100%;
  }
}
