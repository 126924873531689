.interview_detail_highlights {
  height: 25rem;
}

.interview_detail_assistant {
  max-height: 47rem;
  height: 65vh;
}

@include media-breakpoint-up(xxxl) {
  .interview_detail_highlights {
    height: 35rem;
  }
}
