.item-label {
  font-weight: $font-weight-semibold;
}

.text-input,
.text-input:focus {
  color: inherit;
  font-size: inherit;
  border: 0px;
  outline: none;
}
