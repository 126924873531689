.uploadbox {
  input,
  [type='submit'] {
    display: none;
  }

  .uploadbox__uploading,
  .uploadbox__success,
  .uploadbox__error {
    display: none;
  }

  &.is-uploading .box__input {
    visibility: none;
  }
  &.is-uploading .box__uploading {
    display: block;
  }

  &.is-dragover {
    background-color: $theme-border-color;
  }

  background-color: white;
  border: 1px dashed $theme-text-color-light;
  border-radius: 10px;
  padding: 20px;

  .uploadbox.has-advanced-upload .uploadbox__dragndrop {
    display: inline;
  }

  label:hover {
    cursor: pointer;
    color: $link-hover-color;
  }
}
