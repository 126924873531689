.row-form {
  input {
    display: none;
  }
  &.row-form-edit {
    .row-form-static,
    .row-form-btn {
      display: none;
    }
    input {
      display: block;
    }
  }
}

.inline-form {
  display: inline-block;
}
