.tooltip-inner {
  box-shadow: 0px 16px 24px rgba(120, 129, 214, 0.06), 0px 2px 6px rgba(120, 129, 214, 0.04), 0px 0px 1px rgba(120, 129, 214, 0.04);
  text-align: left;
  margin: 1px !important; // This will remove the 1px that overlaps the tooltip-arrow
}

.tooltip.show {
  opacity: 0.9;
}

.light-tooltip {
    opacity: 1 !important;

  .tooltip-inner {
    background-color: $white;
  }

  .tooltip-arrow {
    &::before {
      border-color: $white !important;
    }
  }
}

.top-and-bottom-light-tooltip {
  opacity: 1 !important;

.tooltip-inner {
  background-color: $white;
}

.tooltip-arrow {
  &::before {
    border-top-color: $white !important;
    border-bottom-color: $white !important;
  }
}
}
