
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1680px,
);

@mixin at-breakpoint($breakpoint) {
  $value: map-get($grid-breakpoints, $breakpoint);

  @if $value != null {
    @media (max-width: $value) {
      @content;
    }
  }
}
