@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700&display=swap');

@import 'cronofy';
@import 'variables';
@import '~bootstrap/scss/mixins';
@import 'utilities';

@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/accordion';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/offcanvas';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/spinners';

@import '~react-phone-number-input/style';

@import 'app/styles';

@import 'chips';
@import 'navs';
@import 'interview_detail';
@import '~video.js/dist/video-js';
@import 'video';

.pull-right {
  float: right;
}

.app-auth-branding {
  text-align: center;
}

#referenceCheckApp,
#candidateExperienceApp {
  height: 100%;
  min-height: 100vh;
}

mark,
.mark {
  padding: 0 !important;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
}

.table-fixed {
  table-layout: fixed;
}

.label {
  font-size: $form-label-font-size;
  font-weight: $form-label-font-weight;
}

.badge {
  line-height: 1rem !important;
  font-weight: $font-weight-semibold;
}

i {
  line-height: 1;
}
